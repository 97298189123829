export const GET_ALL_ADMIN_REQUEST =
	"GET_ALL_ADMIN_REQUEST"
export const GET_ALL_ADMIN_SUCCESS =
	"GET_ALL_ADMIN_SUCCESS"
export const GET_ALL_ADMIN_FAIL =
	"GET_ALL_ADMIN_FAIL"

export const ADMIN_LOGIN_REQUEST =
	"ADMIN_LOGIN_REQUEST"
export const ADMIN_LOGIN_SUCCESS =
	"ADMIN_LOGIN_SUCCESS"
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL"

export const GET_ADMIN_DATA_REQUEST =
	"GET_ADMIN_DATA_REQUEST"
export const GET_ADMIN_DATA_SUCCESS =
	"GET_ADMIN_DATA_SUCCESS"
export const GET_ADMIN_DATA_FAIL =
	"GET_ADMIN_DATA_FAIL"
