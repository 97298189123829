import React, { useState, useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import { getAllProducts } from '../actions/productActions'
import { useDispatch, useSelector } from 'react-redux'
import { promocodeCreate } from '../actions/promocodeActions'
import { useNavigate, Link } from 'react-router-dom'
const CreatePromocodeScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	useEffect(() => {
		dispatch(getAllProducts())
	}, [dispatch])
	const [code, setCode] = useState('')
	const [desc, setDesc] = useState('')
	const [codeType, setCodeType] = useState('price')
	const [codeValue, setCodeValue] = useState('')
	const [productsFor, setProductsFor] = useState([])

	const allProducts = useSelector((state) => state.allProducts)
	const { products } = allProducts

	const generateCode = () => {
		let x = ''
		const allLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
		for (let index = 0; index < 6; index++) {
			x += allLetters[Math.floor(Math.random() * allLetters.length)]
		}
		setCode(x)
	}
	const currencyFormat = (num) => {
		return '$ ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	const promocodeProductList = (e, id) => {
		if (e.target.checked) {
			setProductsFor([...productsFor, id])
		} else {
			setProductsFor(productsFor.filter((item) => item !== id))
		}
	}

	const submit = (e) => {
		e.preventDefault()
		dispatch(
			promocodeCreate(
				code,
				desc,
				codeType,
				parseInt(codeValue),
				productsFor,
				navigate
			)
		)
	}

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={10} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						overflowY: 'scroll',
						height: '100vh',
					}}
				>
					<div className='mt-3 p-3'>
						<div className='d-flex align-items-center justify-content-between pe-3'>
							<h4 className='mb-0 fw-bold '>
								{' '}
								<Link
									to={'/promocode'}
									className='text-dark'
								>
									<i class='fa-solid fa-arrow-left'></i>
								</Link>{' '}
								Create Promocode
							</h4>
						</div>
					</div>
					<hr />
					<form onSubmit={submit}>
						<div className='row'>
							<div className='col-6 p-3'>
								<div
									className=' bg-light p-3'
									style={{
										borderRadius: '10px',
									}}
								>
									<div className='p-3  mt-2 '>
										<div className='d-flex'>
											<div class='form-floating w-75 mb-3'>
												<input
													type='text'
													class='form-control'
													id='floatingInput'
													required
													value={code}
													onChange={(e) =>
														setCode(e.target.value.toLocaleUpperCase())
													}
													placeholder='name@example.com'
												/>
												<label for='floatingInput'>Code</label>
											</div>
											<div className='w-25 ps-3  mb-3'>
												<button
													type='button'
													onClick={generateCode}
													className='btn w-100 h-100 btn-success'
												>
													<i class='fa-solid fa-shuffle'></i>
												</button>
											</div>
										</div>

										<div class='form-floating mb-3'>
											<input
												type='text'
												class='form-control'
												id='floatingInput'
												value={desc}
												required
												onChange={(e) => setDesc(e.target.value)}
												placeholder='name@example.com'
											/>
											<label for='floatingInput'>Description</label>
										</div>

										<div
											className='d-flex justify-content-between'
											style={{ gap: '' }}
										>
											<div class='w-50 h-100 pt-1'>
												<div class='form-floating'>
													<select
														onChange={(e) => setCodeType(e.target.value)}
														class='form-select'
														required
														disabled
														id='floatingSelect'
														aria-label='Floating label select example'
													>
														<option
															selected
															value='price'
														>
															Price
														</option>
														<option value='percentage'>Percentage</option>
													</select>
													<label for='floatingSelect'>Code Type</label>
												</div>
											</div>

											<div class='form-floating w-50 mb-3 p-1'>
												<input
													type='number'
													value={codeValue}
													required
													onChange={(e) => setCodeValue(e.target.value)}
													class='form-control'
													id='floatingInput'
													placeholder='name@example.com'
												/>
												<label for='floatingInput'>Value</label>
											</div>
										</div>
										<button className='btn btn-dark w-100 p-3'>
											Create Promocode
										</button>
									</div>
								</div>
							</div>
							<div className='col-6 p-3'>
								<div
									className=' bg-light p-3'
									style={{
										borderRadius: '10px',
									}}
								>
									<div className='   mt-2 '>
										<table class='table p-2 '>
											<thead>
												<tr className='bg-dark text-light p-3'>
													<th scope='col'>#</th>
													<th scope='col'>Name</th>
													<th scope='col'>Price</th>
													<th scope='col'>Discount Price</th>
												</tr>
											</thead>
											<tbody
												className=''
												style={{ cursor: 'pointer' }}
											>
												{products?.map((item, i) => (
													<tr className=''>
														<th scope='row'>
															<input
																type='checkbox'
																name=''
																onChange={(e) =>
																	promocodeProductList(e, item._id)
																}
																id={'checkbox' + i}
															/>
														</th>
														<td>
															<label
																className='w-100'
																htmlFor={'checkbox' + i}
															>
																{' '}
																{item.name}
															</label>
														</td>
														<td style={{ cursor: 'pointer' }}>
															<label
																className='w-100'
																htmlFor={'checkbox' + i}
															>
																{currencyFormat(item.price)}
															</label>{' '}
														</td>
														<td style={{ cursor: 'pointer' }}>
															<label
																className='w-100'
																htmlFor={'checkbox' + i}
															>
																{productsFor.find((x) => x === item._id)
																	? currencyFormat(item.price - codeValue)
																	: '-'}
															</label>{' '}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

export default CreatePromocodeScreen
