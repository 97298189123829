import axios from "axios"
import {
	ADMIN_LOGIN_FAIL,
	ADMIN_LOGIN_REQUEST,
	ADMIN_LOGIN_SUCCESS,
	GET_ALL_ADMIN_FAIL,
	GET_ALL_ADMIN_REQUEST,
	GET_ALL_ADMIN_SUCCESS,
	GET_ADMIN_DATA_FAIL,
	GET_ADMIN_DATA_REQUEST,
	GET_ADMIN_DATA_SUCCESS,
} from "../constants/adminConstants"

export const getAllAdmins =
	() => async (dispatch) => {
		try {
			dispatch({ type: GET_ALL_ADMIN_REQUEST })
			const { data } = await axios.get(
				"/api/admin/all",
			)

			dispatch({
				type: GET_ALL_ADMIN_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ALL_ADMIN_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const loginAdmin =
	(email, password) => async (dispatch) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			}
			dispatch({ type: ADMIN_LOGIN_REQUEST })
			const { data } = await axios.post(
				"/api/admin/login",
				{ email, password },
				config,
			)

			dispatch({
				type: ADMIN_LOGIN_SUCCESS,
				payload: data,
			})
			localStorage.setItem(
				"adminLogin",
				JSON.stringify(data),
			)
		} catch (error) {
			dispatch({
				type: ADMIN_LOGIN_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAdminData =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_ADMIN_DATA_REQUEST })

			const { data } = await axios.post(
				"/api/admin/getadmin",
				{},
				config,
			)

			dispatch({
				type: GET_ADMIN_DATA_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ADMIN_DATA_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
