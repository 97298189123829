import React, { useEffect } from "react"
import Sidebar from "../components/Sidebar"
import "../css/dealer.css"
import axios from "axios"
import { getAllWarranty } from "../actions/formActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
const WarrantyScreen = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getAllWarranty())
	}, [])

	const adminLogin = useSelector(
		(state) => state.adminLogin,
	)
	const { admin } = adminLogin

	const getWarranty = useSelector(
		(state) => state.getWarranty,
	)

	const resentEmail = async (index) => {
		if (
			window.confirm(
				"Resend Confirmation Email ?",
			)
		) {
			try {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${admin.token}`,
					},
				}

				const { data } = await axios.post(
					"/api/form/sent-warranty-email",
					{ warranty: warranty[index] },
					config,
				)

				if (data) {
					alert("MAIL SENT SUCCESSFULLY")
				}
			} catch (error) {
				alert(error)
			}
		}
	}

	const { warranty } = getWarranty
	return (
		<div className='container-fluid  ps-0'>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={6} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						overflowY: "scroll",
						height: "100vh",
					}}>
					<div
						className='mt-2 ps-0 pe-0'
						style={{ paddingTop: "2.6em" }}>
						<div className='d-flex align-items-center justify-content-between pe-3'>
							<h4 className='mb-3 fw-bold'>
								Warranty
							</h4>
							<div className='d-flex mb-3  align-items-center'></div>
						</div>
						<table
							style={{
								borderRadius: "0px",
								boxShadow: "-4px 4px #252730",
							}}
							className=' w-100'>
							<thead>
								<tr className=''>
									<th className='p-4'>Date</th>
									<th className='p-4'>Name</th>
									<th className='p-4'>Email</th>
									<th className='p-4'>
										Mobile Number
									</th>
									<th className='p-4'>Bike</th>
									<th className='p-4'>
										Frame No.
									</th>
									<th className='p-4'>
										Invoice No.
									</th>
									<th className='p-4'>Invoice</th>{" "}
								</tr>
							</thead>
							<tbody className='bg-dark-1'>
								{warranty &&
									warranty.map((item, i) => (
										<tr>
											<td className='ps-4 pt-3 pb-3 '>
												{item.createdAt.slice(
													0,
													10,
												)}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{item.name}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{item.email}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{item.phoneNumber}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{item.bike}
											</td>
											<td className='ps-3 pt-3 pb-3 '>
												{item.frameNumber}
											</td>
											<td className='ps-3 pt-3 pb-3 '>
												{item.invoiceNumber}
											</td>
											<td className=' d-flex  pt-3 pb-3 pe-2  '>
												<a
													href={item.invoice}
													style={{
														borderRadius: "0px",
														boxShadow:
															"-4px 4px #252730",
													}}
													target='_blank'
													className='btn d-block mx-auto  btn-primary'>
													<i class='fa-solid fa-download'></i>
												</a>
												<button
													onClick={() =>
														resentEmail(i)
													}
													style={{
														borderRadius: "0px",
														boxShadow:
															"-4px 4px #252730",
													}}
													className='btn ms-3  d-block mx-auto  btn-success'>
													<i class='fa-solid fa-envelope'></i>
												</button>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WarrantyScreen
