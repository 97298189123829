import {GET_ALL_ORDERS_FAIL,GET_ALL_ORDERS_REQUEST,GET_ALL_ORDERS_SUCCESS,GET_ORDER_BY_ID_FAIL,GET_ORDER_BY_ID_REQUEST,GET_ORDER_BY_ID_SUCCESS} from '../constants/orderConstants'

export const allOrdersReducer = (state = {}, action)=>{
    switch (action.type) {
        case GET_ALL_ORDERS_REQUEST:
            return {loading : true} 
        case GET_ALL_ORDERS_SUCCESS:
            return { loading : false, orders:action.payload}  
        case GET_ALL_ORDERS_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}


export const orderByIdReducer = (state = {}, action)=>{
    switch (action.type) {
        case GET_ORDER_BY_ID_REQUEST:
            return {loading : true} 
        case GET_ORDER_BY_ID_SUCCESS:
            return { loading : false, order:action.payload}  
        case GET_ORDER_BY_ID_FAIL:
                return { loading : false, error:action.payload}      
        default:
            return state
    }
}
