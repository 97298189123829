import React from "react"

const Loader = () => {
	return (
		<div className='position-relative h-100 '>
			<span
				class='loader'
				style={{
					position: "absolute",
					top: "40%",
					left: "42%",
				}}>
				Loading
			</span>
		</div>
	)
}

export default Loader
