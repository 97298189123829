import {
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  GET_PROMOCODE_FAIL,
  CREATE_PROMOCODE_FAIL,
  CREATE_PROMOCODE_REQUEST,
  CREATE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAIL,
} from "../constants/promocodeConstants";

export const getPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROMOCODE_REQUEST:
      return { loading: true };
    case GET_PROMOCODE_SUCCESS:
      return { loading: false, promocode: action.payload };
    case GET_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROMOCODE_REQUEST:
      return { loading: true };
    case CREATE_PROMOCODE_SUCCESS:
      return { loading: false, promocode: action.payload };
    case CREATE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePromocodeReducer = (
  state={},
  action
) => {
  switch (action.type) {
    case DELETE_PROMOCODE_REQUEST:
      return { loading: true };
    case DELETE_PROMOCODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isPromoCodeDeleted: true,
        promocode: action.payload,
      };
    case DELETE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
