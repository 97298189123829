import React, { useEffect } from "react"
import Sidebar from "../components/Sidebar"
import "../css/testride.css"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { getAllProducts } from "../actions/productActions"
import { useNavigate } from "react-router-dom"

const ProductsScreen = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getAllProducts())
	}, [])
	const navigate = useNavigate()

	const allProducts = useSelector(
		(state) => state.allProducts,
	)
	const { products } = allProducts

	const adminInfo = useSelector(
		(state) => state.adminInfo,
	)
	const { adminData } = adminInfo

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={3} />
				</div>
				<div
					className='col-lg-10 position-relative p-0'
					style={{
						overflowY: "scroll",
						height: "100vh",
					}}>
					{adminData &&
					adminData.accessLevel === "Viewer" ? (
						<>
							<h1
								className='fw-bold position-absolute'
								style={{
									fontSize: "3em",
									top: "40%",
									left: "50%",
									transform:
										"translate(-50%,50%)",
								}}>
								NOT AUTHORISED
							</h1>
						</>
					) : (
						<div className='mt-2 ps-3 pe-3'>
							<div className='d-flex align-items-center justify-content-between pe-3'>
								<h4 className='mb-3 fw-bold'>
									Products
								</h4>
							</div>
							<table className='w-100 '>
								<thead>
									<tr className=''>
										<th className='p-4'>
											Sr. No.
										</th>

										<th className='p-4'>Name</th>
										<th className='p-4'>
											Category
										</th>
										<th className='p-4'>Price</th>

										<th className='p-4'>
											In Stock
										</th>
										<th className='p-4'>
											Updated At
										</th>

										<th className='p-4'>
											Status
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody className='bg-dark-1'>
									{products &&
										products
											.sort(
												(a, b) =>
													b.price - a.price,
											)
											.map((item, i) => (
												<tr
													style={{
														cursor: "pointer",
													}}
													onClick={() =>
														navigate(
															`/product/${item._id}`,
														)
													}>
													<td className='ps-4 pt-3 pb-3 '>
														{i + 1}
													</td>

													<td className='ps-4 pt-3 pb-3 '>
														{item.name}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.category}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.price}
													</td>

													<td className='ps-4 pt-3 pb-3 '>
														{item.inStock}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.updatedAt}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.active
															? "ACTIVE"
															: "DRAFT"}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														<button className='btn btn-primary'>
															<i class='fa-solid fa-pen'></i>
														</button>
													</td>
												</tr>
											))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default ProductsScreen
