import {
	ADMIN_LOGIN_FAIL,
	ADMIN_LOGIN_REQUEST,
	ADMIN_LOGIN_SUCCESS,
	GET_ADMIN_DATA_FAIL,
	GET_ADMIN_DATA_REQUEST,
	GET_ADMIN_DATA_SUCCESS,
	GET_ALL_ADMIN_FAIL,
	GET_ALL_ADMIN_REQUEST,
	GET_ALL_ADMIN_SUCCESS,
} from "../constants/adminConstants"

export const allAdminsReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_ALL_ADMIN_REQUEST:
			return { loading: true }
		case GET_ALL_ADMIN_SUCCESS:
			return {
				loading: false,
				admins: action.payload,
			}
		case GET_ALL_ADMIN_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const adminLoginReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case ADMIN_LOGIN_REQUEST:
			return { loading: true }
		case ADMIN_LOGIN_SUCCESS:
			return {
				loading: false,
				admin: action.payload,
			}
		case ADMIN_LOGIN_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const adminInfoReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_ADMIN_DATA_REQUEST:
			return { loading: true }
		case GET_ADMIN_DATA_SUCCESS:
			return {
				loading: false,
				adminData: action.payload,
			}
		case GET_ADMIN_DATA_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
