import React, { useState, useEffect } from "react"
import Sidebar from "../components/Sidebar"
import { getAllOrders } from "../actions/orderActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { useNavigate } from "react-router-dom"
import Loader from "../components/Loader"

const OrdersScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [from, setFrom] = useState("")
	const [to, setTo] = useState("")

	const allOrders = useSelector(
		(state) => state.allOrders,
	)
	const { orders } = allOrders

	useEffect(() => {
		dispatch(getAllOrders())
	}, [])

	const searchQuery = () => {
		dispatch(getAllOrders(name, from, to))
	}

	const currencyFormat = (num) => {
		return (
			"$" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={2} />
				</div>
				<div className='col-lg-10 p-0'>
					{orders && (
						<div className='mt-3 p-3'>
							<div className='d-flex align-items-center justify-content-between pe-3'>
								<h4 className='mb-3 fw-bold '>
									Orders
								</h4>
								<div className='d-flex  align-items-center'>
									<div class='form-floating mb-3 me-3'>
										<input
											type='text'
											class='form-control'
											id='floatingInput'
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											placeholder='name@example.com'
										/>
										<label for='floatingInput text-grey '>
											Name or Mobile Number
										</label>
									</div>
									<div class='form-floating mb-3 me-3'>
										<input
											type='date'
											class='form-control'
											id='floatingInput'
											value={from}
											onChange={(e) =>
												setFrom(e.target.value)
											}
											placeholder='name@example.com'
										/>
										<label for='floatingInput text-grey '>
											From
										</label>
									</div>
									<div class='form-floating mb-3 me-3'>
										<input
											type='date'
											class='form-control'
											id='floatingInput'
											value={to}
											onChange={(e) =>
												setTo(e.target.value)
											}
											placeholder='name@example.com'
										/>
										<label for='floatingInput text-grey'>
											To
										</label>
									</div>
									<button
										onClick={searchQuery}
										className='btn  btn-primary text-light'>
										<i class='fa-solid fa-magnifying-glass'></i>
									</button>
								</div>
							</div>
							<div className='d-flex align-items-center justify-content-between'>
								<nav>
									<div
										class='nav nav-tabs'
										id='nav-tab'
										role='tablist'>
										<button
											class='nav-link active'
											id='nav-home-tab'
											data-bs-toggle='tab'
											data-bs-target='#nav-home'
											type='button'
											role='tab'
											aria-controls='nav-home'
											aria-selected='true'>
											ALL
										</button>
										<button
											class='nav-link'
											id='nav-profile-tab'
											data-bs-toggle='tab'
											data-bs-target='#nav-profile'
											type='button'
											role='tab'
											aria-controls='nav-profile'
											aria-selected='false'>
											Paid
										</button>
										<button
											class='nav-link'
											id='nav-contact-tab'
											data-bs-toggle='tab'
											data-bs-target='#nav-contact'
											type='button'
											role='tab'
											aria-controls='nav-contact'
											aria-selected='false'>
											UnPaid
										</button>
									</div>
								</nav>
								<h6 className='m-0  fw-bold pe-5 '>
									<span className='d-inline-block pe-3'>
										{orders && orders.length}
									</span>
									|
									<span className='d-inline-block ps-3 pe-3'>
										{orders &&
											orders.filter(
												(order) =>
													order.paymentStatus ===
													true,
											).length}
									</span>
									|
									<span className='d-inline-block ps-3'>
										{orders &&
											orders.filter(
												(order) =>
													order.paymentStatus ===
													false,
											).length}
									</span>
								</h6>
							</div>

							<div
								class='tab-content'
								id='nav-tabContent'>
								<div
									class='tab-pane  fade show active'
									id='nav-home'
									style={{
										height: "80vh",
										overflowY: "scroll",
									}}
									role='tabpanel'
									aria-labelledby='nav-home-tab'>
									<table className='  w-100 border-round overflow-hidden'>
										<thead className='position-sticky'>
											<tr
												className=''
												style={{
													top: "0%",
													position: "sticky",
												}}>
												<th className='p-4'>
													Date
												</th>
												<th className='p-4'>
													Order ID
												</th>

												<th className='p-4'>
													Name
												</th>
												<th className='p-4'>
													Email
												</th>
												<th className='p-4'>
													Mob. No.
												</th>

												<th className='p-4'>
													Items
												</th>

												<th className='p-4'>
													Total
												</th>

												{/* <th className='p-4'>
												Status
											</th> */}
											</tr>
										</thead>
										<tbody className=''>
											{orders &&
												orders.map((order) => (
													<tr
														className={`${
															order.paymentStatus &&
															"bg-dark text-light"
														}`}
														style={{
															cursor: "pointer",
														}}
														onClick={() =>
															navigate(
																`/orders/${order._id}`,
															)
														}>
														<td className='ps-4 pt-3 pb-3 '>
															{order.createdAt.slice(
																0,
																10,
															)}
														</td>
														<td className='ps-4 pt-3 pb-3 '>
															EM{" "}
															{String(order._id)
																.slice(-6)
																.toUpperCase()}
														</td>

														<td className='ps-4 pt-3 pb-3 '>
															{order.name}
														</td>
														<td className='ps-4 pt-3 pb-3 '>
															{order.email}
														</td>
														<td className='ps-4 pt-3 pb-3 '>
															{order.phoneNumber}
														</td>
														<td
															className='ps-4 pt-3 pb-3 '
															data-toggle='tooltip'
															data-placement='top'
															title={order.items.map(
																(name) =>
																	name.name,
															)}>
															{order.items.reduce(
																(acc, item) =>
																	acc + item.qty,
																0,
															)}{" "}
														</td>
														<td className='ps-4 pt-3 pb-3 '>
															{currencyFormat(
																order.amount,
															)}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
								<div
									class='tab-pane fade'
									id='nav-profile'
									role='tabpanel'
									style={{
										height: "80vh",
										overflowY: "scroll",
									}}
									aria-labelledby='nav-profile-tab'>
									<table className=' w-100 border-round overflow-hidden'>
										<thead>
											<tr className=''>
												<th className='p-4'>
													Date
												</th>
												<th className='p-4'>
													Order ID
												</th>

												<th className='p-4'>
													Name
												</th>
												<th className='p-4'>
													Email
												</th>
												<th className='p-4'>
													Mob. No.
												</th>

												<th className='p-4'>
													Items
												</th>

												<th className='p-4'>
													Total
												</th>

												{/* <th className='p-4'>
												Order Status
											</th> */}
											</tr>
										</thead>
										<tbody className=''>
											{orders &&
												orders.map(
													(order) =>
														order.paymentStatus && (
															<tr
																className={`${
																	order.paymentStatus &&
																	"bg-dark text-light"
																}`}
																style={{
																	cursor:
																		"pointer",
																}}
																onClick={() =>
																	navigate(
																		`/orders/${order._id}`,
																	)
																}>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.createdAt.slice(
																		0,
																		10,
																	)}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	EM{" "}
																	{String(
																		order._id,
																	)
																		.slice(-6)
																		.toUpperCase()}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.name}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.email}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{
																		order.phoneNumber
																	}
																</td>
																<td
																	className='ps-4 pt-3 pb-3 '
																	data-toggle='tooltip'
																	data-placement='top'
																	title={order.items.map(
																		(name) =>
																			name.name,
																	)}>
																	{order.items.reduce(
																		(acc, item) =>
																			acc +
																			item.qty,
																		0,
																	)}{" "}
																</td>

																<td className='ps-4 pt-3 pb-3 '>
																	{currencyFormat(
																		order.amount,
																	)}
																</td>

																{/* <td className='ps-4 pt-3 d-flex align-items-center  pb-3 '>
																{
																	order.orderStatus
																}
															</td> */}
															</tr>
														),
												)}
										</tbody>
									</table>
								</div>
								<div
									class='tab-pane fade'
									id='nav-contact'
									role='tabpanel'
									style={{
										height: "80vh",
										overflowY: "scroll",
									}}
									aria-labelledby='nav-contact-tab'>
									<table className=' w-100 border-round overflow-hidden'>
										<thead>
											<tr className=''>
												<th className='p-4'>
													Date
												</th>
												<th className='p-4'>
													Order ID
												</th>

												<th className='p-4'>
													Name
												</th>
												<th className='p-4'>
													Email
												</th>
												<th className='p-4'>
													Mob. No.
												</th>

												<th className='p-4'>
													Items
												</th>

												<th className='p-4'>
													Total
												</th>

												{/* <th className='p-4'>
												Order Status
											</th> */}
											</tr>
										</thead>
										<tbody className=''>
											{orders &&
												orders.map(
													(order) =>
														!order.paymentStatus && (
															<tr
																className={`${
																	order.paymentStatus &&
																	"bg-dark text-light"
																}`}
																style={{
																	cursor:
																		"pointer",
																}}
																onClick={() =>
																	navigate(
																		`/orders/${order._id}`,
																	)
																}>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.createdAt.slice(
																		0,
																		10,
																	)}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	EM{" "}
																	{String(
																		order._id,
																	)
																		.slice(-6)
																		.toUpperCase()}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.name}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{order.email}
																</td>
																<td className='ps-4 pt-3 pb-3 '>
																	{
																		order.phoneNumber
																	}
																</td>
																<td
																	className='ps-4 pt-3 pb-3 '
																	data-toggle='tooltip'
																	data-placement='top'
																	title={order.items.map(
																		(name) =>
																			name.name,
																	)}>
																	{order.items.reduce(
																		(acc, item) =>
																			acc +
																			item.qty,
																		0,
																	)}{" "}
																</td>

																<td className='ps-4 pt-3 pb-3 '>
																	{currencyFormat(
																		order.amount,
																	)}
																</td>

																{/* <td className='ps-4 pt-3 d-flex align-items-center  pb-3 '>
																{
																	order.orderStatus
																}
															</td> */}
															</tr>
														),
												)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}
					{!orders && <Loader />}
				</div>
			</div>
		</div>
	)
}

export default OrdersScreen
