import {
	GET_CONTACT_FAIL,
	GET_CONTACT_REQUEST,
	GET_CONTACT_SUCCESS,
	GET_INSURANCE_FAIL,
	GET_INSURANCE_REQUEST,
	GET_INSURANCE_SUCCESS,
	GET_TESTRIDE_FAIL,
	GET_TESTRIDE_REQUEST,
	GET_TESTRIDE_SUCCESS,
	GET_WARRANTY_FAIL,
	GET_WARRANTY_REQUEST,
	GET_WARRANTY_SUCCESS,
	GET_PARTNER_FAIL,
	GET_PARTNER_REQUEST,
	GET_PARTNER_SUCCESS,
} from "../constants/formConstants"

export const getTestridesReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_TESTRIDE_REQUEST:
			return { loading: true }
		case GET_TESTRIDE_SUCCESS:
			return {
				loading: false,
				testRides: action.payload,
			}
		case GET_TESTRIDE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const getWarrantyReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_WARRANTY_REQUEST:
			return { loading: true }
		case GET_WARRANTY_SUCCESS:
			return {
				loading: false,
				warranty: action.payload,
			}
		case GET_WARRANTY_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const getContactReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_CONTACT_REQUEST:
			return { loading: true }
		case GET_CONTACT_SUCCESS:
			return {
				loading: false,
				contacts: action.payload,
			}
		case GET_CONTACT_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const getInsuranceReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_INSURANCE_REQUEST:
			return { loading: true }
		case GET_INSURANCE_SUCCESS:
			return {
				loading: false,
				insurance: action.payload,
			}
		case GET_INSURANCE_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const getPartnerReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_PARTNER_REQUEST:
			return { loading: true }
		case GET_PARTNER_SUCCESS:
			return {
				loading: false,
				partners: action.payload,
			}
		case GET_PARTNER_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
