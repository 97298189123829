import DashboardScreen from './screens/DashboardScreen'
import TestRideScreen from './screens/TestRideScreen'

import PromoCodeScreen from './screens/PromoCodeScreen'
import WarrantyScreen from './screens/WarrantyScreen'
import PartnerWithUsScreen from './screens/PartnerWithUsScreen'
import ProductsScreen from './screens/ProductsScreen'
import ContactUsScreen from './screens/ContactUsScreen'
import InsuranceScreen from './screens/InsuranceScreen'
import CreatePromocodeScreen from './screens/CreatePromocodeScreen'
import LoginScreen from './screens/LoginScreen'
import OrdersScreen from './screens/OrdersScreen'
import OrderScreen from './screens/OrderScreen'
import ProductScreen from './screens/ProductScreen'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
function App() {
	return (
		<Router>
			<Routes>
				<Route
					path='/'
					element={<DashboardScreen />}
				/>
				<Route
					path='/product'
					element={<ProductsScreen />}
				/>
				<Route
					path='/product/:id'
					element={<ProductScreen />}
				/>
				<Route
					path='/login'
					element={<LoginScreen />}
				/>
				<Route
					path='/warranty'
					element={<WarrantyScreen />}
				/>
				<Route
					path='/testride'
					element={<TestRideScreen />}
				/>

				<Route
					path='/orders'
					element={<OrdersScreen />}
				></Route>
				<Route
					path='/orders/:id'
					element={<OrderScreen />}
				></Route>

				<Route
					path='partner-with-us'
					element={<PartnerWithUsScreen />}
				/>

				{/* <Route
					path='/admins'
					element={<AdminListScreen />}></Route> */}
				{/* 
				<Route
					path='/dealer/create'
					element={<CreateDealerScreen />}
				/> */}

				<Route
					path='/insurance'
					element={<InsuranceScreen />}
				/>
				<Route
					path='/contact'
					element={<ContactUsScreen />}
				/>

				<Route
					path='/promocode'
					element={<PromoCodeScreen />}
				/>

				<Route
					path='/promocode/create'
					element={<CreatePromocodeScreen />}
				/>
			</Routes>
		</Router>
	)
}

export default App
