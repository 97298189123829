import {
	GET_ALL_ORDERS_FAIL,
	GET_ALL_ORDERS_REQUEST,
	GET_ALL_ORDERS_SUCCESS,
	GET_ORDER_BY_ID_FAIL,
	GET_ORDER_BY_ID_REQUEST,
	GET_ORDER_BY_ID_SUCCESS,
} from "../constants/orderConstants"
import axios from "axios"

export const getAllOrders =
	(name, from, to) =>
	async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_ALL_ORDERS_REQUEST })
			const { data } = await axios.post(
				"/api/order/all",
				{ name, from, to },
				config,
			)

			dispatch({
				type: GET_ALL_ORDERS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ALL_ORDERS_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getOrderByID =
	(id) => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_ORDER_BY_ID_REQUEST })
			const { data } = await axios.get(
				`/api/order/${id}`,
				config,
			)

			dispatch({
				type: GET_ORDER_BY_ID_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ORDER_BY_ID_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
