import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  allAdminsReducer,
  adminLoginReducer,
  adminInfoReducer,
} from "./reducers/adminReducers.js";
import { allOrdersReducer, orderByIdReducer } from "./reducers/orderReducers";
import {
  allProductsReducer,
  productByIdReducer,
  updateProductByIdReducer,
} from "./reducers/productReducers";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  getTestridesReducer,
  getWarrantyReducer,
  getContactReducer,
  getInsuranceReducer,
  getPartnerReducer,
} from "./reducers/formReducers";
import { getDealersReducer } from "./reducers/dealerReducers";
import {
  getPromocodeReducer,
  createPromocodeReducer,
  deletePromocodeReducer,
} from "./reducers/promocodeReducers";

const reducer = combineReducers({
  allAdmins: allAdminsReducer,
  allOrders: allOrdersReducer,
  orderById: orderByIdReducer,
  allProducts: allProductsReducer,
  productById: productByIdReducer,
  updateProductById: updateProductByIdReducer,
  getTestrides: getTestridesReducer,
  getWarranty: getWarrantyReducer,
  getContact: getContactReducer,
  getDealers: getDealersReducer,
  getPromocode: getPromocodeReducer,
  getInsurance: getInsuranceReducer,
  getPartner: getPartnerReducer,
  adminLogin: adminLoginReducer,
  adminInfo: adminInfoReducer,
  createPromocode: createPromocodeReducer,
  deletePromocode: deletePromocodeReducer,
});

const userInfoFromStorage = localStorage.getItem("adminLogin")
  ? JSON.parse(localStorage.getItem("adminLogin"))
  : null;

const initialState = {
  adminLogin: { admin: userInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
