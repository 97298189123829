import axios from "axios";
import {
  GET_PROMOCODE_FAIL,
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  CREATE_PROMOCODE_FAIL,
  CREATE_PROMOCODE_REQUEST,
  CREATE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAIL,
} from "../constants/promocodeConstants";

export const getAllPromocodes = () => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };
    dispatch({ type: GET_PROMOCODE_REQUEST });
    const { data } = await axios.get(
      "/api/promocode/all",

      config
    );

    dispatch({
      type: GET_PROMOCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PROMOCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const promocodeCreate =
  (code, desc, codeType, codeValue, productsFor, navigate) =>
  async (dispatch, getState) => {
    try {
      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };
      dispatch({ type: CREATE_PROMOCODE_REQUEST });
      const { data } = await axios.post(
        "/api/promocode/create",
        { code, desc, codeType, codeValue, productsFor },
        config
      );

      dispatch({
        type: CREATE_PROMOCODE_SUCCESS,
        payload: data,
      });

      navigate("/promocode");
    } catch (error) {
      dispatch({
        type: CREATE_PROMOCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const promocodeDelete = (id) => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };
    console.log(config);
    dispatch({ type: DELETE_PROMOCODE_REQUEST });
    const { data } = await axios.delete(
      `api/promocode/delete/${id}`,

      config
    );

    dispatch({
      type: DELETE_PROMOCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PROMOCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
