import {
	UPDATE_PRODUCT_FAIL,
	UPDATE_PRODUCT_REQUEST,
	UPDATE_PRODUCT_SUCCESS,
	GET_ALL_PRODUCTS_FAIL,
	GET_ALL_PRODUCTS_REQUEST,
	GET_ALL_PRODUCTS_SUCCESS,
	GET_PRODUCT_BY_ID_FAIL,
	GET_PRODUCT_BY_ID_REQUEST,
	GET_PRODUCT_BY_ID_SUCCESS,
} from "../constants/productConstants"

export const allProductsReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_ALL_PRODUCTS_REQUEST:
			return { loading: true }
		case GET_ALL_PRODUCTS_SUCCESS:
			return {
				loading: false,
				products: action.payload,
			}
		case GET_ALL_PRODUCTS_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const productByIdReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case GET_PRODUCT_BY_ID_REQUEST:
			return { loading: true }
		case GET_PRODUCT_BY_ID_SUCCESS:
			return {
				loading: false,
				product: action.payload,
			}
		case GET_PRODUCT_BY_ID_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const updateProductByIdReducer = (
	state = {},
	action,
) => {
	switch (action.type) {
		case UPDATE_PRODUCT_REQUEST:
			return { loading: true }
		case UPDATE_PRODUCT_SUCCESS:
			return {
				loading: false,
				updatedProduct: action.payload,
			}
		case UPDATE_PRODUCT_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}
