import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { getAllOrders } from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { promocodeDelete, getAllPromocodes } from "../actions/promocodeActions";
import { Link } from "react-router-dom";
const PromoCodeScreen = () => {
  const dispatch = useDispatch();

  const getPromocode = useSelector((state) => state.getPromocode);
  const deletePromocode = useSelector((state) => state.deletePromocode);
  const { isPromoCodeDeleted } = deletePromocode;

  const { promocode } = getPromocode;

  const allOrders = useSelector((state) => state.allOrders);
  const { orders } = allOrders;

  useEffect(() => {
    Promise.all([dispatch(getAllPromocodes()), dispatch(getAllOrders())]);
  }, [dispatch]);

  const handleDeleteClick = (id) => {
    const confirmDelete = window.confirm(
      "Do you really want to delete this promocode ?"
    );
    if (confirmDelete) {
      dispatch(promocodeDelete(id));
    }
  };

  useEffect(() => {
    if (isPromoCodeDeleted) {
      dispatch(getAllPromocodes());
    }
  }, [isPromoCodeDeleted]);

  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={10} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div className="mt-3 p-3 ">
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h4 className="mb-3 fw-bold ">Promocodes</h4>
              <Link className="btn btn-primary mb-3" to="/promocode/create">
                <i className="fa-solid fa-plus me-1"></i> Create Promocode
              </Link>
            </div>
            {orders && (
              <div className="d-flex">
                <div>
                  <h3 className=" d-line fw-bold pe-3 text-danger">
                    {orders && orders.filter((order) => order.promoCode).length}
                  </h3>{" "}
                </div>

                <h3 className="ps-3 d-line fw-bold pe-3 text-muted">|</h3>
                <h3 className="ps-3 d-line fw-bold text-success">
                  {orders &&
                    orders.filter(
                      (order) => order.paymentStatus && order.promoCode
                    ).length}
                </h3>
              </div>
            )}

            <table className=" w-100">
              <thead>
                <tr className="">
                  <th className="p-4">Sr.No</th>

                  <th className="p-4">Code</th>
                  <th className="p-4">Desc</th>
                  <th className="p-4">Total Orders</th>
                  <th className="p-4">Total Paid Orders</th>
                  <th className="p-4"></th>
                </tr>
              </thead>
              <tbody className="bg-dark-1">
                {promocode &&
                  promocode.map((item, i) => (
                    <tr>
                      <td className="ps-4 pt-3 pb-3 ">{i + 1}</td>

                      <td className="ps-4  pt-3 pb-3 ">{item.code}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.desc}</td>
                      <td className="ps-4 pt-3 pb-3 ">
                        {orders &&
                          orders.filter(
                            (order) =>
                              order.promoCode &&
                              order.promoCode.code === item.code
                          ).length}
                      </td>

                      <td className="ps-4 pt-3 pb-3 ">
                        {orders &&
                          orders.filter(
                            (order) =>
                              order.paymentStatus &&
                              order.promoCode &&
                              order.promoCode.code === item.code
                          ).length}
                      </td>
                      <td className="ps-4 pt-3 pb-3 ">
                        <span
                          role="button"
                          onClick={() => handleDeleteClick(item._id)}
                        >
                          <i class="fa-solid fa-trash"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCodeScreen;
