import axios from "axios"
import {
	UPDATE_PRODUCT_FAIL,
	UPDATE_PRODUCT_REQUEST,
	UPDATE_PRODUCT_SUCCESS,
	GET_ALL_PRODUCTS_FAIL,
	GET_ALL_PRODUCTS_REQUEST,
	GET_ALL_PRODUCTS_SUCCESS,
	GET_PRODUCT_BY_ID_FAIL,
	GET_PRODUCT_BY_ID_REQUEST,
	GET_PRODUCT_BY_ID_SUCCESS,
} from "../constants/productConstants"

export const getAllProducts =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_ALL_PRODUCTS_REQUEST })
			const { data } = await axios.get(
				"/api/product/all",
				config,
			)

			dispatch({
				type: GET_ALL_PRODUCTS_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ALL_PRODUCTS_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getProductByID =
	(id) => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({
				type: GET_PRODUCT_BY_ID_REQUEST,
			})
			const { data } = await axios.get(
				`/api/product/${id}`,
				config,
			)

			dispatch({
				type: GET_PRODUCT_BY_ID_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_PRODUCT_BY_ID_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const updateProduct =
	(
		id,
		name,
		description,
		productType,
		price,
		slashPrice,
		gst,
		withoutGST,
		inStock,
		category,
		specs,
	) =>
	async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: UPDATE_PRODUCT_REQUEST })
			const { data } = await axios.put(
				`/api/product/update`,
				{
					id,
					name,
					description,
					productType,
					price,
					slashPrice,
					gst,
					withoutGST,
					inStock,
					category,
					specs,
				},
				config,
			)

			dispatch({
				type: UPDATE_PRODUCT_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: UPDATE_PRODUCT_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
