import axios from "axios"
import {
	GET_CONTACT_FAIL,
	GET_CONTACT_REQUEST,
	GET_CONTACT_SUCCESS,
	GET_INSURANCE_FAIL,
	GET_INSURANCE_REQUEST,
	GET_INSURANCE_SUCCESS,
	GET_PARTNER_FAIL,
	GET_PARTNER_REQUEST,
	GET_PARTNER_SUCCESS,
	GET_TESTRIDE_FAIL,
	GET_TESTRIDE_REQUEST,
	GET_TESTRIDE_SUCCESS,
	GET_WARRANTY_FAIL,
	GET_WARRANTY_REQUEST,
	GET_WARRANTY_SUCCESS,
} from "../constants/formConstants"

export const getAllTestrides =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_TESTRIDE_REQUEST })
			const { data } = await axios.get(
				"/api/form/testride",
				config,
			)

			dispatch({
				type: GET_TESTRIDE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_TESTRIDE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllWarranty =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_WARRANTY_REQUEST })
			const { data } = await axios.get(
				"/api/form/warranty",
				config,
			)

			dispatch({
				type: GET_WARRANTY_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_WARRANTY_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllContact =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_CONTACT_REQUEST })
			const { data } = await axios.get(
				"/api/form/contact",
				config,
			)

			dispatch({
				type: GET_CONTACT_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_CONTACT_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllInsurance =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_INSURANCE_REQUEST })
			const { data } = await axios.get(
				"/api/form/insurance",
				config,
			)

			dispatch({
				type: GET_INSURANCE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_INSURANCE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllPartners =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}

			dispatch({ type: GET_PARTNER_REQUEST })
			const { data } = await axios.get(
				"/api/form/partner",
				config,
			)

			dispatch({
				type: GET_PARTNER_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_PARTNER_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
