import React, { useEffect } from 'react'
import '../css/sidebar.css'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
const Sidebar = ({ active }) => {
	const navigate = useNavigate()

	const adminLogin = useSelector((state) => state.adminLogin)
	const { admin } = adminLogin

	const adminInfo = useSelector((state) => state.adminInfo)
	const { adminData } = adminInfo

	useEffect(() => {
		if (admin) {
			if (admin.token) {
				if (!adminData) {
					navigate('/login')
				}
			}
		} else {
			navigate('/login')
		}
	}, [admin, adminData, navigate])

	return (
		<div
			className='sidebar   text-light '
			style={{
				height: '100vh',
				overflowY: 'auto',
			}}
		>
			<div className='mt-0 pt-3 '>
				<img
					src='../images/white.png'
					width='170'
					className=' d-block ps-3 img-fluid d-block   '
					alt=''
				/>
			</div>

			<div className='sidebar-links p-2 pt-0 mt-4 '>
				<Link
					to={'/'}
					className={`d-flex text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 1 && 'active-link'
					}`}
				>
					<i class='fa-solid pt-1 me-3 fa-earth-americas'></i>
					<span>Overview</span>
				</Link>
				<Link
					to={'/orders'}
					className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 2 && 'active-link'
					}`}
				>
					<i class='fa-solid pt-1 me-3 fa-cart-flatbed'></i>
					<span>Orders</span>
				</Link>
				<Link
					to={'/product'}
					className={`d-flex  text-decoration-none text-light sidebar-item mt-3 mb-3   p-3 ${
						active === 3 && 'active-link'
					}`}
				>
					<i class='fa-solid pt-1 me-3  fa-box-open'></i>
					<span>Products</span>
				</Link>

				<Link
					to={'/warranty'}
					className={`d-flex text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 6 && 'active-link'
					}`}
				>
					<i class='fa-solid pt-1 me-3  fa-gears'></i>
					<span>Warranty</span>
				</Link>

				<Link
					to={'/partner-with-us'}
					className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 8 && 'active-link'
					}`}
				>
					<i class=' pt-1 me-3 fa-solid fa-handshake'></i>
					<span>Partner</span>
				</Link>
				<Link
					to={'/contact'}
					className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 9 && 'active-link'
					}`}
				>
					<i class='fa-solid pt-1 me-3 fa-envelope'></i>
					<span>Contact Us</span>
				</Link>
				<Link
					to={'/promocode'}
					className={`d-flex  text-decoration-none text-light sidebar-item mt-3   p-3 ${
						active === 10 && 'active-link'
					}`}
				>
					<i class='fa-solid fa-tags pt-1 me-3'></i>

					<span>Promocode</span>
				</Link>
			</div>
		</div>
	)
}

export default Sidebar
