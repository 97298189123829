export const GET_TESTRIDE_REQUEST =
	"GET_TESTRIDE_REQUEST"
export const GET_TESTRIDE_SUCCESS =
	"GET_TESTRIDE_SUCCESS"
export const GET_TESTRIDE_FAIL =
	"GET_TESTRIDE_FAIL"

export const GET_WARRANTY_REQUEST =
	"GET_WARRANTY_REQUEST"
export const GET_WARRANTY_SUCCESS =
	"GET_WARRANTY_SUCCESS"
export const GET_WARRANTY_FAIL =
	"GET_WARRANTY_FAIL"

export const GET_INSURANCE_REQUEST =
	"GET_INSURANCE_REQUEST"
export const GET_INSURANCE_SUCCESS =
	"GET_INSURANCE_SUCCESS"
export const GET_INSURANCE_FAIL =
	"GET_INSURANCE_FAIL"

export const GET_CONTACT_REQUEST =
	"GET_CONTACT_REQUEST"
export const GET_CONTACT_SUCCESS =
	"GET_CONTACT_SUCCESS"
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL"

export const GET_PARTNER_REQUEST =
	"GET_PARTNER_REQUEST"
export const GET_PARTNER_SUCCESS =
	"GET_PARTNER_SUCCESS"
export const GET_PARTNER_FAIL = "GET_PARTNER_FAIL"
