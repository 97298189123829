import React, { useEffect, useState } from "react"
import Sidebar from "../components/Sidebar"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import axios from "axios"
import { useParams } from "react-router-dom"

import { getOrderByID } from "../actions/orderActions"

const OrderScreen = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getOrderByID(id))
	}, [])

	const [loading, setLoading] = useState(false)

	const orderById = useSelector(
		(state) => state.orderById,
	)
	const { order } = orderById

	const adminLogin = useSelector(
		(state) => state.adminLogin,
	)
	const { admin } = adminLogin

	const currencyFormat = (num) => {
		return (
			"$" +
			" " +
			num
				.toFixed(0)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		)
	}

	const getISTTime = (time) => {
		var localTime = new Date(time).toLocaleString(
			undefined,
			{
				timeZone: "Australia/Sydney",
			},
		)
		return localTime
	}

	const resentEmail = async () => {
		if (
			window.confirm(
				"Resend Confirmation Email ?",
			)
		) {
			try {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${admin.token}`,
					},
				}

				const { data } = await axios.post(
					"/api/order/send-email",
					{ order: order.orderInfo },
					config,
				)

				if (data) {
					alert("MAIL SENT SUCCESSFULLY")
				}
			} catch (error) {
				alert(error)
			}
		}
	}

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={13} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						height: "100vh",
						overflowY: "scroll",
					}}>
					{order && (
						<>
							<div className='d-flex justify-content-between'>
								<div className='mt-3  p-3'>
									<span className='  d-block'>
										Ordered on
									</span>
									<h3 className={`fw-bold `}>
										{getISTTime(
											order.orderInfo.createdAt,
										)}
									</h3>
								</div>
								<div
									className='mt-3 d-flex align-items-center  p-3 pe-5'
									style={{ gap: "2rem" }}>
									{order.orderInfo
										.paymentStatus && (
										<div>
											<button
												style={{
													borderRadius: "0px",
													boxShadow:
														"-4px 4px #252730",
												}}
												onClick={resentEmail}
												className='btn btn-primary fw-bold'>
												Resend Confirmation Email
											</button>
										</div>
									)}

									<div>
										<span className=''>
											Payment Status
										</span>
										{order.orderInfo
											.paymentStatus ? (
											<h3
												className=''
												style={{
													color: "#10b068",
													fontWeight: "900",
												}}>
												Paid
											</h3>
										) : (
											<h3
												style={{
													color: "red",
													fontWeight: "900",
												}}>
												Unpaid
											</h3>
										)}
									</div>
								</div>
							</div>

							<div className='container-fluid'>
								<div className='row mt-3'>
									<div className='col-12 col-md-7  '>
										<div
											className='bg-light  p-4'
											style={{
												borderRadius: "10px",
											}}>
											<h6 className='mb-2'>
												Order ID :{" "}
												<span className='ms-3 fw-bold'>
													{" "}
													EM{" "}
													{String(order._id)
														.slice(-6)
														.toUpperCase()}
												</span>
											</h6>
											<hr />

											<p>
												name :{" "}
												<span className='ms-3'>
													{" "}
													{order.orderInfo.name}
												</span>
											</p>
											<p>
												email :{" "}
												<span className='ms-3'>
													{order.orderInfo.email}
												</span>
											</p>
											<p>
												Phone Number :{" "}
												<span className='ms-3'>
													{
														order.orderInfo
															.phoneNumber
													}
												</span>
											</p>
										</div>
										<div className=' mt-4'>
											<div
												className='bg-light p-4'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Shipping Details:
												</h6>
												<hr />
												<div className='d-flex'>
													<p className='col-2'>
														Address :{" "}
													</p>{" "}
													<p>
														{
															order.orderInfo
																.address
																.addressLine1
														}
														,{" "}
														{
															order.orderInfo
																.address
																.addressLine2
														}
														,{" "}
														{
															order.orderInfo
																.address.city
														}
														,{" "}
														{
															order.orderInfo
																.address.state
														}
														.{" "}
														{
															order.orderInfo
																.address.pincode
														}
													</p>
												</div>
											</div>
										</div>
										<div
											className='d-flex pe-3'
											style={{ gap: "1rem" }}>
											<div
												className='mt-4  col-5 bg-light p-4'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Order Invoice Details:
												</h6>
												<hr />
												<p className='d-flex justify-content-between pe-3'>
													Total Items :{" "}
													<span className=''>
														{" "}
														{order.orderInfo.items.reduce(
															(acc, item) =>
																acc + item.qty,
															0,
														)}
													</span>
												</p>

												<p className='d-flex justify-content-between pe-3'>
													Promocode :{" "}
													<span className=''>
														{" "}
														{order.orderInfo
															.promoCode
															? order.orderInfo
																	.promoCode.code
															: order.orderInfo
																	.promoCode}
													</span>
												</p>
												<p className='d-flex justify-content-between pe-3'>
													Total Amount
													<span className='fw-normal'>
														{" "}
														{currencyFormat(
															order.orderInfo
																.amount,
														)}
													</span>
												</p>
											</div>
											<div
												className=' col-7 p-4 mt-4 bg-light'
												style={{
													borderRadius: "10px",
												}}>
												<h6 className='mb-2'>
													Payment Details:
												</h6>
												<hr />
												{order.orderInfo
													.paymentStatus && (
													<>
														<p className='d-flex justify-content-between pe-3'>
															Payment ID:
															<span className='fw-normal'>
																{
																	order.orderInfo
																		.paymentDetails
																		.id
																}
															</span>
														</p>

														<p className='d-flex justify-content-between pe-3'>
															Method:
															<span className='fw-normal text-uppercase'>
																{
																	order.orderInfo
																		.paymentType
																}
															</span>
														</p>
													</>
												)}
											</div>
										</div>
									</div>

									<div className='col-12 col-md-5  '>
										<div
											className='bg-light p-4'
											style={{
												borderRadius: "10px",
											}}>
											<h6 className='mb-2'>
												Items:
											</h6>
											<hr />
											{order.orderInfo.items.map(
												(item) => (
													<>
														<div className='row  shipping-item-info mt-4 align-items-center'>
															<div
																className='col-4 p-2 '
																style={{
																	background:
																		"#F4F5F7",
																}}>
																<img
																	src={
																		item
																			.cartImages[
																			item.color
																		]
																	}
																	className='img-fluid'
																	alt=''
																/>
															</div>
															<div className='col-8 p-3 pt-0'>
																<div className='mb-3 item-name pb-2'>
																	<h5 className='fw-bold mb-0 color-dark'>
																		{item.name}
																	</h5>
																</div>
																<p className='fw-light mb-2 w-100 d-flex justify-content-between '>
																	{" "}
																	<span className='col-7'>
																		Color :
																	</span>{" "}
																	<span className='fw-bold col-5'>
																		{
																			item.models[
																				item.color
																			].colorName
																		}
																	</span>
																</p>
																{item.variant &&
																item.variantType !==
																	undefined ? (
																	<p className='fw-light mb-2 w-100 d-flex justify-content-between '>
																		{" "}
																		<span className='col-7'>
																			{
																				item
																					.variant[0]
																					.variantType
																			}{" "}
																			:
																		</span>{" "}
																		<span className='fw-bold col-5'>
																			{
																				item
																					.variant[
																					item
																						.variantType
																				]
																					.variantName
																			}
																		</span>
																	</p>
																) : (
																	""
																)}
																<p className='fw-light mb-2 w-100 d-flex justify-content-between '>
																	{" "}
																	<span className='col-7'>
																		Quantity :
																	</span>{" "}
																	<span className='fw-bold d-flex align-items-center justify-content-between  col-5'>
																		{" "}
																		<span>
																			{item.qty}
																		</span>{" "}
																	</span>
																</p>
																<p className='fw-light mb-0 w-100 d-flex justify-content-between '>
																	{" "}
																	<span className='col-7'>
																		Price :
																	</span>{" "}
																	<span className='fw-bold d-flex align-items-center justify-content-between  col-5'>
																		{" "}
																		<span>
																			{currencyFormat(
																				item.qty *
																					item.accPrice,
																			)}
																		</span>{" "}
																	</span>
																</p>
															</div>
														</div>
													</>
												),
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default OrderScreen
