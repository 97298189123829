export const GET_PROMOCODE_REQUEST = 'GET_PROMOCODE_REQUEST'
export const GET_PROMOCODE_SUCCESS = 'GET_PROMOCODE_SUCCESS'
export const GET_PROMOCODE_FAIL = 'GET_PROMOCODE_FAIL'

export const CREATE_PROMOCODE_REQUEST = 'CREATE_PROMOCODE_REQUEST'
export const CREATE_PROMOCODE_SUCCESS = 'CREATE_PROMOCODE_SUCCESS'
export const CREATE_PROMOCODE_FAIL = 'CREATE_PROMOCODE_FAIL'

export const DELETE_PROMOCODE_REQUEST = 'DELETE_PROMOCODE_REQUEST'
export const DELETE_PROMOCODE_SUCCESS = 'DELETE_PROMOCODE_SUCCESS'
export const DELETE_PROMOCODE_FAIL = 'DELETE_PROMOCODE_FAIL'