import React, { useState, useEffect } from "react"
import {
	loginAdmin,
	getAdminData,
} from "../actions/adminActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"
import { useNavigate } from "react-router-dom"

const LoginScreen = () => {
	const navigate = useNavigate()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const dispatch = useDispatch()

	const adminLogin = useSelector(
		(state) => state.adminLogin,
	)
	const { admin } = adminLogin

	const adminInfo = useSelector(
		(state) => state.adminInfo,
	)
	const { adminData } = adminInfo

	useEffect(() => {
		if (admin) {
			if (admin.token) {
				dispatch(getAdminData())
			}
		}
	}, [admin, dispatch])

	useEffect(() => {
		if (adminData) {
			navigate("/")
		}
	}, [adminData])

	const login = (e) => {
		e.preventDefault()
		dispatch(loginAdmin(email, password))
	}
	return (
		<div
			className=''
			style={{
				height: "100vh",
				background: "#252730",
			}}>
			<div className='container h-100 position-relative mb-5'>
				<div
					className='p-5 mx-auto position-absolute'
					style={{
						top: "40%",
						borderRadius: "10px",
						left: "50%",
						width: "40%",
						background: "white",
						transform: "translate(-50%,-50%)",
					}}>
					<img
						src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png'
						className='img-fluid w-50 d-block mx-auto'
						alt=''
					/>
					<form onSubmit={login}>
						<div class=' mt-lg-5 form-floating mb-3'>
							<input
								type='email'
								class='form-control'
								id='floatingInput'
								required
								onChange={(e) =>
									setEmail(e.target.value)
								}
								value={email}
								placeholder='name@example.com'
							/>
							<label for='floatingInput'>
								Email address
							</label>
						</div>
						<div class='form-floating'>
							<input
								type='password'
								required
								onChange={(e) =>
									setPassword(e.target.value)
								}
								value={password}
								class='form-control'
								id='floatingPassword'
								placeholder='Password'
							/>
							<label for='floatingPassword'>
								Password
							</label>
						</div>
						<button
							type='submit'
							className='btn w-100 fw-bold mt-3 p-3 btn-primary btn-block'>
							Submit
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default LoginScreen
