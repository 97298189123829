import React, { useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import { getAllOrders } from '../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import '../css/dashboard.css'
import { getAllTestrides } from '../actions/formActions'
import Loader from '../components/Loader'

const DashboardScreen = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		Promise.all([dispatch(getAllOrders()), dispatch(getAllTestrides())])
	}, [dispatch])

	const allOrders = useSelector((state) => state.allOrders)
	const { orders } = allOrders

	const getTestrides = useSelector((state) => state.getTestrides)
	const { testRides } = getTestrides

	const currencyFormat = (num) => {
		return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}

	return (
		<div className='container-fluid   ps-0 '>
			<div className='row  '>
				<div className='col-lg-2 '>
					<Sidebar active={1} />
				</div>

				<div className='col-lg-10'>
					{orders ? (
						<div
							className='row'
							style={{
								overflowY: 'scroll',
								height: '100vh',
							}}
						>
							<div className='col-3 p-3  '>
								<div
									className='dashborad-card  p-4'
									style={{
										borderTopRightRadius: '10px',
										borderBottomRightRadius: '10px',
										borderLeft: '4px solid blue',
									}}
								>
									<div className='d-flex justify-content-between'>
										<div>
											<h6 className='text-muted'>Total Revenue</h6>
											<h4 className='fw-bold  '>
												{currencyFormat(
													orders
														.filter((item) => item.paymentStatus === true)
														.reduce((acc, item) => acc + item.amount, 0)
												)}
											</h4>
										</div>
										<div>
											<i
												style={{
													fontSize: '2em',
												}}
												class='fa-solid text-muted  fa-wallet'
											></i>
										</div>
									</div>
								</div>
							</div>
							<div className='col-3 p-3  '>
								<div
									className='dashborad-card  p-4'
									style={{
										borderTopRightRadius: '10px',
										borderBottomRightRadius: '10px',
										borderLeft: '4px solid #10b068',
									}}
								>
									<div className='d-flex justify-content-between'>
										<div>
											<h6 className='text-muted'>Sales</h6>
											<h5 className='fw-bold  '>
												{
													orders.filter((item) => item.paymentStatus === true)
														.length
												}
											</h5>
										</div>
										<div>
											<i
												style={{
													fontSize: '2em',
												}}
												class='fa-solid fa-box-archive text-muted'
											></i>
										</div>
									</div>
								</div>
							</div>
							<div className='col-3 p-3  '>
								<div
									className='dashborad-card  p-4'
									style={{
										borderTopRightRadius: '10px',
										borderBottomRightRadius: '10px',
										borderLeft: '4px solid #2a6315',
									}}
								>
									<div className='d-flex justify-content-between'>
										<div>
											<h6 className='text-muted'>Lead</h6>
											<h5 className='fw-bold  '>{orders.length}</h5>
										</div>
										<div>
											<i
												style={{
													fontSize: '2em',
												}}
												class=' text-muted fa-solid fa-chart-line'
											></i>
										</div>
									</div>
								</div>
							</div>
							<div className='col-3 p-3  '>
								<div
									className='dashborad-card  p-4'
									style={{
										borderTopRightRadius: '10px',
										borderBottomRightRadius: '10px',
										borderLeft: '4px solid #bae8ff',
									}}
								>
									<div className='d-flex justify-content-between'>
										<div>
											<h6 className='text-muted'>Test Rides</h6>
											<h5 className='fw-bold  '>
												{testRides && testRides.length}
											</h5>
										</div>
										<div>
											<i
												style={{
													fontSize: '2em',
												}}
												class=' text-muted fa-solid fa-chart-line'
											></i>
										</div>
									</div>
								</div>
							</div>
							<div className='col-12 p-4 border-round  '>
								<p className='fw-bold'>Month Sales Graph</p>
								<iframe
									title='sales graph'
									style={{
										height: '700px',

										borderRadius: '15px',
										background: '#fff',
										boxShadow: '0px 2px 10px 0 rgba(70, 76, 79, .2)',
									}}
									className='w-100'
									src='https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=2ab31a82-ebfd-421d-b4fd-46557ab873c4&maxDataAge=3600&theme=light&autoRefresh=true'
								></iframe>
							</div>
						</div>
					) : (
						<Loader />
					)}
				</div>
			</div>
		</div>
	)
}

export default DashboardScreen
