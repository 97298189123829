import React, { useEffect } from "react"
import Sidebar from "../components/Sidebar"
import "../css/testride.css"
import { getAllTestrides } from "../actions/formActions"
import {
	useDispatch,
	useSelector,
} from "react-redux"

const TestRideScreen = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getAllTestrides())
	}, [])

	const getTestrides = useSelector(
		(state) => state.getTestrides,
	)
	const { testRides } = getTestrides

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={4} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						overflowY: "scroll",
						height: "100vh",
					}}>
					<div className='mt-3 p-3'>
						<div className='d-flex align-items-center justify-content-between pe-3'>
							<h4 className='mb-3 fw-bold'>
								TestRide
							</h4>
							<div className='d-flex  align-items-center'>
								<div class='form-floating mb-3 me-3'>
									<input
										type='date'
										class='form-control'
										id='floatingInput'
										placeholder='name@example.com'
									/>
									<label for='floatingInput text-grey '>
										From
									</label>
								</div>
								<div class='form-floating mb-3 me-3'>
									<input
										type='date'
										class='form-control'
										id='floatingInput'
										placeholder='name@example.com'
									/>
									<label for='floatingInput text-grey'>
										To
									</label>
								</div>
								<button className='btn btn-success text-light'>
									<i class='fa-solid fa-download'></i>
								</button>
							</div>
						</div>
						<table className=' w-100'>
							<thead>
								<tr className=''>
									<th className='p-4'>Date</th>
									<th className='p-4'>Name</th>
									<th className='p-4'>
										Mobile Number
									</th>
									<th className='p-4'>Email</th>
									<th className='p-4'>Address</th>
								</tr>
							</thead>

							<tbody className='bg-dark-1'>
								{testRides &&
									testRides.map((ride, i) => (
										<tr>
											<td className='ps-4 pt-3 pb-3 '>
												{ride.createdAt.slice(
													0,
													10,
												)}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{ride.userName}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{ride.userEmail}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{ride.userPhoneNumber}
											</td>
											<td className='ps-4 pt-3 pb-3 '>
												{ride.address}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TestRideScreen
